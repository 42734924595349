import React, { useEffect, useState } from 'react';
import { graphql } from 'gatsby'
import _ from "lodash";
import Modal from 'react-bootstrap/Modal';

import Layout from "gatsby-theme-starberry-lomondgroup/src/components/Layout"
import Info from "gatsby-theme-starberry-lomondgroup/src/components/Info/Info"
import Features from "gatsby-theme-starberry-lomondgroup/src/components/Features/Features";
import Customer from "gatsby-theme-starberry-lomondgroup/src/components/Customer/Customer";
import Journey from "gatsby-theme-starberry-lomondgroup/src/components/Journey/Journey";
import HomeBanner from "gatsby-theme-starberry-lomondgroup/src/components/Banner/HomeBanner"
import LeadooScript from "gatsby-theme-starberry-lomondgroup/src/components/LeadooScript/LeadooScript";
import TrustpilotReviews from "gatsby-theme-starberry-lomondgroup/src/components/TrustpilotReviews/TrustpilotReviews";
import BannerSlider from "gatsby-theme-starberry-lomondgroup/src/components/BannerSlider/Slider";

import ImageTextCarousal from "gatsby-theme-starberry-lomondgroup/src/components/ImageTextCarousal/ImageTextCarousal"
import TilesBlock from "gatsby-theme-starberry-lomondgroup/src/components/TilesBlock/Tiles"
import LocalExperience from "gatsby-theme-starberry-lomondgroup/src/components/LocalExperience/LocalExperience"

import {RenderComponent} from "gatsby-theme-starberry-lomondgroup/src/modules/render-component";

import News from "gatsby-theme-starberry-lomondgroup/src/components/News/News"

import WhyChoose from "gatsby-theme-starberry-lomondgroup/src/components/Counter/WhyChoose";

export default function HomepageTemplate(props){

//   var GQLPage = props.GQLPage;
//   var GQLModules = props.GQLModules;

    const renderComponent = RenderComponent()

    const [modalShow, setModalShow] = useState(true)
    const [count, setCount] = useState(0);

    var GQLPage = props?.data.glstrapi?.page;
    var GQLModules = props?.data.glstrapi?.page?.add_components;
    var SiteConfig = props?.data.glstrapi?.siteConfig;

    /* =========== Temp code ========= */
    // var pages = props?.data.glstrapi?.pages;
    // var burgermenu = props?.data.glstrapi?.burgerMenus;
    // var topmenu = props?.data.glstrapi?.socialLink?.top_menu?.links;
    // var primary_menu = props?.data.glstrapi?.socialLink?.primary_menu;
    // var footer_menu = props?.data.glstrapi?.socialLink?.footer_menu;

    // // Burger menu merge
    // let burgermenu_merge = []
    // burgermenu?.length > 0 && burgermenu.map(item => {
    //   burgermenu_merge.push(item?.menu)

    //   item?.add_menu?.length > 0 && item.add_menu.map(item2 => {
    //     item2?.menu && burgermenu_merge.push(item2?.menu)
    //   })
    // })

    // // Burger menu merge
    // let footermenu_merge = []
    // footer_menu?.length > 0 && footer_menu.map(item => {
    //   item?.footer_links?.length > 0 && item.footer_links.map(item2 => {
    //     item2?.menu_item && footermenu_merge.push(item2?.menu_item)
    //   })
    // })

    // // Burger menu merge
    // let primary_menu_merge = []
    // primary_menu?.length > 0 && primary_menu.map(item => {
    //   primary_menu_merge.push(item?.menu_items?.menu_item)

    //   item?.sub_menu_item?.length > 0 && item.sub_menu_item.map(item2 => {
    //     item2?.menu_item && primary_menu_merge.push(item2?.menu_item)
    //   })
    // })

    // let filter_non_pages = []
    // let filter_non_pages_2 = []
    // let filter_non_pages_3 = []
    // let filter_non_pages_4 = []

    // if(pages.length > 0){
    //   filter_non_pages = pages.filter(({id:id1}) => !burgermenu_merge.some(item2 => item2.page.id === id1))
    // }
    // if(filter_non_pages.length > 0){
    //   filter_non_pages_2 = filter_non_pages.filter(({id:id1}) => !topmenu.some(item2 => item2?.menu_item?.page.id === id1))
    // }

    // if(filter_non_pages_2.length > 0){
    //   filter_non_pages_3 = filter_non_pages_2.filter(({id:id1}) => !footermenu_merge.some(item2 => item2?.page.id === id1))
    // }

    // if(filter_non_pages_3.length > 0){
    //   filter_non_pages_4 = filter_non_pages_3.filter(({id:id1}) => !primary_menu_merge.some(item2 => item2?.page?.id === id1))
    // }

    // // if(filter_non_pages.length > 0){
    // //   filter_non_pages_2 = filter_non_pages.filter(({id:id1}) => burgermenu.map(item2 => !item2?.add_menu.map(subitem => subitem?.menu && subitem?.menu?.page?.id === id1)))
    // // }

    // console.log("filter_non_pages", filter_non_pages_4, primary_menu_merge);
    /* =========== Temp code ========= */


    const closeModal = () => {
        setModalShow(false)
    }

    useEffect(() => {
      // Access count value from session storage
      var pageView = sessionStorage.getItem("pageView");
      if (pageView === null) {
        // Initialize page views count
        pageView = 1;
      } else {
        // Increment count
        pageView = Number(pageView) + 1;
      }
      // Update session storage
      sessionStorage.setItem("pageView", pageView);
      setCount(pageView);
    }, []); 

    //console.log("props ==>", props)

    useEffect(() => {
      // Access count value from session storage
      var pageView = sessionStorage.getItem("pageView");
      if (pageView === null) {
        // Initialize page views count
        pageView = 1;
      } else {
        // Increment count
        pageView = Number(pageView) + 1;
      }
      // Update session storage
      sessionStorage.setItem("pageView", pageView);
      setCount(pageView);
    }, []); 

  var isJourneySec = _.find(GQLModules, function (o) { return o.__typename === "GLSTRAPI_ComponentComponentsShowPropertyJourney" && o.show });

  //console.log("GQLModules ==>", GQLModules);

  let pathname = GQLPage?.menu?.slug ? GQLPage?.menu?.slug.replace(/-/g, '_') : "";

  //console.log("pathname =>", pathname, leadooPageSlot[pathname]);

  return(
    <Layout seo_meta={GQLPage.seo_meta} isJourneySec={isJourneySec} menu_slug={GQLPage?.menu?.slug} popular_search={GQLPage?.menu?.popular_search} GQLPage={GQLPage}>


        {GQLPage?.banner_section?.banner_image && <HomeBanner banner_section = {GQLPage.banner_section} GQLPage={GQLPage}/> }

        {GQLModules?.length > 0 && GQLModules?.map((Module, index) => {
            return(
                <>
                    {(!GQLPage?.banner_section?.banner_image && Module?.__typename === "GLSTRAPI_ComponentComponentsSliderComponent") && <BannerSlider key={Module} Module={Module} GQLPage={GQLPage}/> }

                    {Module?.__typename === "GLSTRAPI_ComponentComponentsLeadooChatComponent" && <LeadooScript key={Module} Module={Module}/> }

                    {Module.__typename === "GLSTRAPI_ComponentComponentsWhyChooseComponent" && <WhyChoose key={Module} Module={Module} /> }

                    {Module?.__typename === "GLSTRAPI_ComponentComponentsTilesBoxComponent" && renderComponent === "true" && <TilesBlock key={Module} Module={Module} GQLPage={GQLPage} /> }

                    {Module.__typename === "GLSTRAPI_ComponentComponentsHomeTilesComponent" && <Info key={Module} Module={Module} GQLPage={GQLPage} pathname={pathname} /> }

                    {Module.__typename === "GLSTRAPI_ComponentComponentsVideoSection" &&  <Customer key={Module} Module={Module} GQLPage={GQLPage}/>}

                    {Module.__typename === "GLSTRAPI_ComponentComponentsLocalExperience" && <LocalExperience key={Module} Module={Module} GQLPage={GQLPage}/>}

                    {Module.__typename === "GLSTRAPI_ComponentComponentsTrustpilotComponent" && renderComponent === "true" && <TrustpilotReviews key={Module} Module={Module} />}

                    {Module.__typename === "GLSTRAPI_ComponentComponentsShowFeaturedProperty" && Module.show && renderComponent === "true" &&  <Features key={Module} Module={Module} menu_slug={GQLPage?.menu?.slug}/> }

                    {Module.__typename === "GLSTRAPI_ComponentComponentsShowNewsInsight" && Module.show &&  renderComponent === "true" && <News key={Module} Module={Module}/> }

                    {Module.__typename === "GLSTRAPI_ComponentComponentsImageTextCarousel" && renderComponent === "true" && <ImageTextCarousal key={Module} Module={Module} strapi_id={GQLPage.id} GQLPage={GQLPage}/> }

                    {Module.__typename === "GLSTRAPI_ComponentComponentsShowPropertyJourney" && Module.show && <Journey key={Module} />}
                </>                
            )
        })}


        {SiteConfig?.welcome_banner_image?.url && GQLPage?.menu?.slug === "home" && renderComponent === "true" && (
            count === 1 &&
            <Modal
                show={modalShow}
                onHide={closeModal}
                size="lg"
                className="welcome_banner"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    {/* <Modal.Title id="contained-modal-title-vcenter">
                        {TeamList.name}
                    </Modal.Title> */}
                </Modal.Header>
                <Modal.Body>
                    <img src={SiteConfig?.welcome_banner_image?.url} alt={SiteConfig?.welcome_banner_image?.alternativeText} />
                </Modal.Body>
            </Modal>
            )
        }
    </Layout>
  )

}


export const query = graphql`
  query HomepageQueryCustom($pageId: ID!) {
    glstrapi {

      siteConfig {
        welcome_banner_image {
          alternativeText
          url
        }
      }
      
      pages(where: {publish: true, layout_nin: "form_template"}) {
        id
        title
        menu {
          slug
        }
      }

      burgerMenus {
        menu {
          slug
          page {
            id
            title
          }
        }
        add_menu {
          menu {
            page {
              id
              title
            }
            slug
          }
        }
      }

      socialLink {

        top_menu {
          links {
            menu_item {
              page {
                id
                title
              }
              slug
            }
          }
        }

        primary_menu {
          id
          menu_items {
            menu_item {
              page {
                id
                title
              }
              slug
            }
          }
          sub_menu_item {
            menu_item {
              page {
                id
                title
              }
              slug
            }
          }          
        }


        footer_menu {
          footer_links {
            menu_item {
              page {
                id
                title
              }
              slug
            }
          }
        }

      }
      
      page(id: $pageId) {
        id
        alias
        title
        choose_form
        form_to_email_id
        content
        layout
        publish
        page_class
        imagetransforms
        menu {
          label
          slug
          id
          link_type
          popular_search{
            popular_search{
              title
              content
            }
          }
          parent {
            label
            slug
            link_type
            parent {
              label
              slug
              link_type
              parent {
                label
                slug
                link_type
              }
            }
          }
        }
        banner_section {
          title
          show_search
          show_reviews
          id
          content
          banner_image {
            alternativeText
            url
          }
          mobile_banner_image {
            alternativeText
            url
          }
          banner_style
          
          button_cta_1 {
            theme
            target
            label
            custom_link
            id
            menu_item {
              slug
              parent {
                slug
                parent {
                  slug
                  parent {
                    slug
                  }
                }
              }
            }
          }
          button_cta_2 {
            theme
            target
            label
            id
            custom_link
            menu_item {
              slug
              parent {
                slug
                parent {
                  slug
                  parent {
                    slug
                  }
                }
              }
            }
          }

        }
        seo_meta {
          title
          keywords
          description
          id
          image {
            alternativeText
            url
          }
        }
        add_components {
          __typename

          ... on GLSTRAPI_ComponentComponentsWhyChooseComponent {
            id
            title
            description
            why_choose_module {
              id
              title
              upload_icon {
                url
                alternativeText
              }
              content
              cta_label
              cta_menu{
                slug
                parent {
                  slug
                  parent {
                    slug
                  }
                }
              }
              cta_custom_link
            }
          }

          ... on GLSTRAPI_ComponentComponentsSliderComponent {
            id
            show_reviews
            show_property_search
            slider_module {
              id
              title
              content
              image {
                url
                alternativeText
              }
              button_cta {
                label
                theme
                target
                custom_link
                id
                menu_item {
                  slug
                  parent {
                    slug
                    parent {
                      slug
                    }
                  }
                }
              }
            }
          }

          ... on GLSTRAPI_ComponentComponentsLocalExperience {
            id
            title
            content
            background_color: bg_color
            image_align
            button_cta {
              theme
              target
              custom_link
              id
              label
              menu_item {
                slug
                parent {
                  slug
                  parent {
                    slug
                    parent {
                      slug
                    }
                  }
                }
              }
            }
            image {
              url
              alternativeText
            }
          }

          ... on GLSTRAPI_ComponentComponentsTrustpilotComponent {
            id
            title
            content
            bg_image {
              url
              alternativeText
            }
            bgclr:bg_color
          }

          ... on GLSTRAPI_ComponentComponentsLeadooChatComponent {
            id
            title
            script_code
            content
            bgrd_color:bg_color
          }

          ... on GLSTRAPI_ComponentComponentsTilesBoxComponent {
            id
            heading_text
            content
            tiles_overlay_color
            number_of_tiles
            tiles_layout_type
            tiles_module {
              title
              id
              content
              image {
                url
                alternativeText
              }
              button_cta {
                label
                theme
                target
                custom_link
                menu_item {
                  slug
                  parent {
                    slug
                    parent {
                      slug
                    }
                  }
                }
              }
            }
          }

          ... on GLSTRAPI_ComponentComponentsHomeTilesComponent {
            home_tiles_block {
              id
              content
              title
              layout_type
              image {
                url
                alternativeText
              }
              button_cta {
                theme
                target
                label
                id
                custom_link
                menu_item {
                  slug
                  parent {
                    slug
                    parent {
                      slug
                    }
                  }
                }
              }
            }
          }
          
          ... on GLSTRAPI_ComponentComponentsShowNewsInsight {
            id
            Title
            show
            select_type

          }

          ... on GLSTRAPI_ComponentComponentsShowFeaturedProperty {
            id
            show
            title
            tab_list {
              add_tab
              id
              tab_name
            }
            background_clr:bg_color
          }

          ... on GLSTRAPI_ComponentComponentsVideoSection {
            id
            title
            content
            read_more_content
            embed_video_url
            bg_color
            button_CTA:Button_CTA {
              theme
              target
              label
              custom_link
              menu_item {
                slug
                parent {
                  slug
                  parent {
                    slug
                    parent {
                      slug
                    }
                  }
                }
              }
            }
            image {
              url
              alternativeText
            }
          }
          
          ... on GLSTRAPI_ComponentComponentsShowPropertyJourney {
            id
            show
          }

          ... on GLSTRAPI_ComponentComponentsImageTextCarousel {
            id
            heading_text
            image_text_carousel_module {
              title
              content
              image {
                alternativeText
                url
              }
              button_cta {
                id
                label
                target
                theme
                custom_link
                menu_item {
                  slug
                  parent {
                    slug
                    parent {
                      slug
                    }
                  }
                }
              }
            }
          }

        }
      }
    
    } 
}`